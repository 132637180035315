<template>
<div class="container padding-container">
    <el-button class="topBtns" type="primary" icon="el-icon-plus" @click="handleEditShow()" v-if="auth('PROVIDER', 'ADMIN')">新增学校</el-button>
    <div class="border-container">
        <el-row class="search-container" type="flex" align="middle" justify="space-between">
            <el-col :lg="2" :sm="4" :xs="8">
                <span class="list-title">学校管理</span>
            </el-col>
            <el-col :lg="22" :sm="20" :xs="16" class="search-area">
                <el-col :span='4' class="search-item" v-if="auth('PROVIDER', 'ADMIN')">
                    <el-select class="circular-bead-select" v-model="searchForm.org_id" clearable placeholder="所属机构" @change="pageChange(1)">
                        <el-option v-for="item in orgList" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </el-col>
                <el-col :span='4' class="search-item">
                    <el-input class="circular-bead-input" v-model='searchForm.name' placeholder="学校名称" prefix-icon="el-icon-search"
                        title="按回车键搜索" @keyup.enter.native="pageChange(1)" @clear="pageChange(1)">
                    </el-input>
                </el-col>
                <el-col :span='4' class="search-item">
                    <el-input class="circular-bead-input" v-model='searchForm.contact_name' placeholder="联系人名称" prefix-icon="el-icon-search"
                        title="按回车键搜索" @keyup.enter.native="pageChange(1)" @clear="pageChange(1)">
                    </el-input>
                </el-col>
                <el-col :span='4' class="search-item">
                    <el-input class="circular-bead-input" v-model='searchForm.contact_phone' placeholder="联系人手机号" prefix-icon="el-icon-search"
                        title="按回车键搜索" @keyup.enter.native="pageChange(1)" @clear="pageChange(1)">
                    </el-input>
                </el-col>
                <el-col :span='2' class="search-item">
                    <el-button v-if="auth('ADMIN')" class="topBtns" type="primary" @click="handleExport">导出</el-button>
                </el-col>
            </el-col>
        </el-row>
        <div class="table-container">
            <el-tabs v-model="searchForm.status" @tab-click="pageChange(1)" class="tabs" v-if="auth('ADMIN')">
                <el-tab-pane label="全部园区列表" name="0"/>
                <el-tab-pane label="试用园区列表" name="100"/>
                <el-tab-pane label="订阅园区列表" name="400"/>
                <el-tab-pane label="已过期园区列表" name="-100"/>
            </el-tabs>
            <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                <el-table-column prop="id" label="ID" width="60" align="center" v-if='auth("ADMIN")'/>

                <el-table-column prop="name" label="学校名称" min-width="150" :show-overflow-tooltip="true">
                    <div slot-scope="scope" class="hover-text-colourful fz-bold" @click="routerChange(1, scope.row)">
                        {{scope.row.name}}
                    </div>
                </el-table-column>

                <el-table-column prop="contact_name" label="联系人姓名" min-width="100" align="center"/>
                <el-table-column prop="contact_phone" label="联系人手机" min-width="120" align="center"/>

                <el-table-column prop="create_time" label="创建时间" min-width="155" align="center">
                    <template slot-scope="scope">
                        {{scope.row.create_time | timeFilter}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="130" align="center" v-if="auth('PROVIDER', 'ADMIN')">
                    <template slot-scope="scope">
                        <el-button type="text" class="icon-btn" @click="handleEditShow(scope.row)">
                            <el-tooltip placement="top" content="编辑">
                                <i class="iconfont icon-edit" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="handleDelete(scope.row.id)">
                            <el-tooltip placement="top" content="删除">
                                <i class="iconfont icon-delete" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="routerChange(1, scope.row)">
                            <el-tooltip placement="top" content="详情">
                                <i class="iconfont icon-check" />
                            </el-tooltip>
                        </el-button>
                    </template>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <el-pagination layout="total, sizes, prev, pager, next" background @size-change="pageSizeChange"
                @current-change="pageChange" :current-page="currentPage" :page-sizes="[total, 10, 15, 20, 25]"
                :total="total" :page-size="pageSize">
            </el-pagination>
        </div>
    </div>
    <template class="dialog-container">
        <el-dialog :title="editTitle" :visible.sync="editVisible" class="school-edit-dialog"
            width="600px" :close-on-click-modal="editType === 'new' ? false : true" @close="handleEditClose">
            <div class="dialog-content">
                <div class="content-floor">
                    <span class="key">学校名称</span>
                    <el-input v-model="editForm.name"></el-input>
                </div>
                <div class="content-floor">
                    <span class="key">联系人名称</span>
                    <el-input v-model="editForm.contactName"></el-input>
                </div>
                <div class="content-floor">
                    <span class="key">联系人手机号</span>
                    <el-input v-model="editForm.contactPhone"></el-input>
                </div>
                <div class="content-floor" style="height: 32px;">
                    <span class="key">功能模块</span>
                    <div class="value">
                        <el-checkbox v-model="editForm.educationManage">教学管理</el-checkbox>
                        <el-checkbox v-model="editForm.operationManage">运营管理</el-checkbox>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button type="primary" @click="handleEditConfirm()">保存</el-button>
                <el-button class="cancel" @click="editVisible = false">取消</el-button>
            </div>
        </el-dialog>
    </template>
</div>
</template>

<script>
import Core from '../../core';
import Base from '@/core/api/base'
import { baseUrl } from '@/core/api/config'

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    data() {
        return {
            total: 0,
            currentPage: 1,
            pageSize: 10,

            searchForm: {
                org_id: '',
                contact_name: '',
                contact_phone: '',
                name: '',
                status: '',
            },
            orgList: [],

            tableData: [],

            editVisible: false,
            editType: '',
            editTitle: '',
            editForm: {
                id: 0,
                name: '',
                contactName: '',
                contactPhone: '',

                educationManage: '',
                operationManage: '',
                providerId: '',
            },
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.getTableData()
        if (this.auth('PROVIDER', 'ADMIN')) {
            this.getOrgList()
        }
    },
    methods: {
        auth: Core.Utils.auth,
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        routerChange(key, item) {
            switch (key) {
                case 1:
                    this.$router.push({
                        path: '/school/school-detail',
                        query: {
                            id: item.id,
                        }
                    })
                    break;
            }
        },

        getTableData() {
            let org_id = this.auth('PROVIDER', 'ADMIN') ? this.searchForm.org_id : Core.Data.getBelong().id
            Core.Api.School.list(
                this.currentPage,
                this.pageSize,
                org_id,
                this.searchForm.name,
                this.searchForm.contact_name,
                this.searchForm.contact_phone,
                this.searchForm.status,
            ).then(res => {
                console.log("getTableData -> res", res);
                this.total = res.count;
                this.tableData = res.list;
            });
        },
        getOrgList() {
            Core.Api.Org.listName(1).then(res => {
                this.orgList = res.list;
            });
        },

        handleEditShow(item) {
            if (item) {
                this.editType = 'edit';
                this.editTitle = '修改学校';
                this.editForm = {
                    id: item.id,
                    name: item.name,
                    contactName: item.contact_name,
                    contactPhone: item.contact_phone,
                    educationManage: item.education_manage ? true : false,
                    operationManage: item.operation_manage ? true : false,
                    providerId: item.provider_id,
                    orgId: item.org_id,
                }
            } else {
                this.editType = 'new';
                this.editTitle = '新增学校';
            }
            this.editVisible = true;
        },
        handleEditClose() {
            this.editVisible = false;
            Object.assign(this.$data.editForm, this.$options.data().editForm)
        },
        handleEditConfirm() {
            if (this.editForm.name === '' ||
                this.editForm.contactName === '' ||
                this.editForm.contactPhone === '' ) {
                return this.$message.warning('请填写完整');
            }

            if (!/^1[3456789]\d{9}$/.test(this.editForm.contactPhone)) {
                return this.$message.warning('手机号码有误，请正确填写');
            }

            Core.Api.School.save(
                this.editForm.id,
                this.editForm.name,
                this.editForm.contactName,
                this.editForm.contactPhone,
                this.editForm.educationManage ? 1 : 0,
                this.editForm.operationManage ? 1 : 0,
                this.editForm.providerId,
                this.editForm.orgId,
            ).then((res) => {
                this.$message.success('保存成功');
                this.getTableData();
                this.handleEditClose();
            }).catch(err => {
                console.log('error', err);
            });
        },
        handleDelete(id) {
            this.$confirm('确定执行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.School.delete(id).then((res) => {
                    this.$message.success('删除成功!');
                    this.getTableData()
                });
            });
        },

        handleExport() {
            let url = `${baseUrl}/admin/1/school/export?${Base.transformObjectToUrlencodedData({
                token: Core.Data.getToken()
            })}`
            console.log('url>>>', url);
            window.open(url)
        }
    }
};
</script>

<style lang="scss" scoped>
.padding-container {
    @import '@/common/styles/table.scss';
    .table-container {
        background-color: #fff;
        box-sizing: border-box;
        padding: 5px 20px;
    }
    .dialog-content {
        .content-floor {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0px;
            }
            .key {
                display: block;
                width: 120px;
            }
            .value {
                width: 100%;
            }
        }
    }
}
</style>
